<template>
  <div id="login" class="login" lang="en-US">
    <div class="login__box">
      <div class="login__box-logo">
        <a href="//bookimed.com" target="_blank">
          <b>Booki</b>med
        </a>
      </div>
      <div class="login__box-body">
        <div class="login__box-form-group">
          <div class="login__box-form-group-input" :class="{'error' : errorText}">
            <input placeholder="Логин или Email" v-model="name">
            <i class="tim-icons icon-pencil"></i>
          </div>
          <div class="login__box-form-error" v-show="errorText">{{errorText}}</div>
        </div>
        <div class="login__box-form-group">
          <div class="login__box-form-group-input" :class="{'error' : errorText}">
            <input placeholder="Пароль" v-model="password" type="password">
            <i class="tim-icons icon-email-85"></i>
          </div>
          <div class="login__box-form-error" v-show="errorText">{{errorText}}</div>
        </div>
        <button class="login__box-submit" @click="login">
          Войти
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import APIAuth from '@/api/modules/auth';
import axios from 'axios';
import Cookies from "js-cookie";

export default {
  data: () => ({
    name: '',
    password: '',
    errorText: '',
    domainType: process.env.NODE_ENV === 'development' ? '.run' : window.location.hostname.slice(window.location.hostname.lastIndexOf('.')),
  }),
  mounted () {
    window.addEventListener('keyup', (event) => event.keyCode == 13 && this.login());
  },
  methods: {
    checkIsAdmin(token) {
      return axios.get(`https://auth.bookimed${this.domainType}/api/v1/oauth/tokens/info?access_token=${token}`).then(response => {
        if (response.data && response.data.scopes && response.data.scopes.admin) {
          return response.data.scopes.admin.id === 'admin';
        }
        return false;
      }).catch(() => false);
    },
    setSession(Authorization) {
      return axios.post(`https://admin.bookimed${this.domainType}/login`, {}, {
        headers: { Authorization }
      });
    },
    login () {
      if (!this.name || !this.password) {
        this.errorText = 'Login or Email can not be empty';
      } else {
        this.errorText = '';
        let bodyFormData = new FormData();

        bodyFormData.set('login', this.name);
        bodyFormData.set('password', this.password);
        this.$store.dispatch('setLoader', true);

        return APIAuth.userAuthorization(bodyFormData).then(async (response) => {
          if (response && response.data && response.data.token) {
            const isAdmin = await this.checkIsAdmin(response.data.token);

            if (isAdmin) {
              const domain = window.location.hostname.split('.').slice(-2).join('.');
              Cookies.set('vT', window.btoa(response.data.token), { path: '/', domain, expires: 1 / 3 });
              Cookies.set('adminToken', response.data.token, { path: '/', domain, expires: 1 / 3 });
              if (this.$route.query.redirectUrl) {
                window.open(window.atob(this.$route.query.redirectUrl), '_self');
              } else {
                this.$router.push({ name: 'Doctors' });
              }
              await this.setSession(response.data.token);
            } else {
              this.errorText = 'Incorrect credentials. Try again.';
            }
          } else {
            this.errorText = 'Incorrect credentials. Try again.';
          }
        }).finally(() => this.$store.dispatch('setLoader', false));
      }
    }
  }
};
</script>
<style scoped lang="scss">
.login{
  height: 100vh;
  background: #d2d6de !important;
  &__box{
    width: 360px;
    padding-top: 7%;
    margin: auto;
    &-submit{
      background-color: #3c8dbc;
      border-color: #367fa9;
      width: 100%;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      cursor: pointer;
    }
    &-form-error{
      color: #dd4b39;
      margin-top: 5px;
      font-size: 14px;
    }
    &-form-group{
      margin-bottom: 15px;
      &-input{
        position: relative;
        &.error input{
          border-color: #dd4b39;
        }
      }
      i{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
        font-size: 18px;
      }
      input{
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 43px 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      }
    }
    &-body{
      background: #fff;
      padding: 20px;
      border-top: 0;
      color: #666;
    }
    &-logo {
      text-align: center;
      margin-bottom: 25px;
      a{
        font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
        font-size: 35px;
        text-align: center;
        font-weight: 300;
        color: #333;
        text-decoration: none;
      }
    }
  }
}
</style>
